import { Box, useTheme } from "@mui/material";
import { rounder } from "Helpers/rounder";
import { CategoryTotal } from "Models/report";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, SideBarDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useState } from "react";
import { tokens } from "theme";

export interface ProjectResourceLevel1Props {
    categoryTotals: Array<CategoryTotal>;
    estimateId: string | undefined;
}

export default function ProjectResourceLevel1(props: ProjectResourceLevel1Props) {
    const [rowData, setRowData] = useState<Array<CategoryTotal>>([]);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const groupDefaultExpanded = -1;
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const [dataTypeDefinitions] = useState<any>({
        amount: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => {
                return rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2);
            }
        }
    })

    const onGridReady = useCallback(() => {
        setRowData([...props.categoryTotals])
    }, [props.categoryTotals])

    const getRowStyle = (params: any) => {
        if (params.node.group) {
            return { fontWeight: 'bold' };
        }
    };

    const [columnDefs] = useState<Array<ColDef<CategoryTotal>>>([
        {
            field: 'displayId',
            headerName: 'ID',
            hide: false,
            cellRenderer: 'agGroupCellRenderer',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            width: 150,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            showRowGroup: true,
        },
        {
            field: 'description',
            flex: 1,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'quantityPercentage',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            width: 175,
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'estimatedQuantityPercentage',
            width: 175,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'amount',
            width: 175,
            enableValue: true,
            aggFunc: 'sum',
            cellDataType: 'amount',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'estimatedAmount',
            width: 175,
            aggFunc: 'sum',
            cellDataType: 'amount',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
    ]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const columnToolPanelParams = useMemo<SideBarDef>(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        };
    }, []);

    return <>
        <Box style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
            <AgGridReact<CategoryTotal>
                dataTypeDefinitions={dataTypeDefinitions}
                rowData={rowData}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={columnToolPanelParams}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={groupDefaultExpanded}
                suppressClickEdit={true}
                getRowStyle={getRowStyle}
                groupIncludeTotalFooter={true}
            />
        </Box>
    </>;
}