import AppRoutes from 'Components/AppRoutes';
import { ThemeProvider } from '@emotion/react';
import { Box, CssBaseline } from '@mui/material';
import { useAuth0 } from "@auth0/auth0-react";
import { PageLoader } from 'Components/PageLoader';
import { useEffect, useState } from 'react';
import { ColorModeContext, useMode } from 'theme';
import Sidebar from 'Pages/Global/Sidebar';
import UnAuthorizedError from 'Components/UnAuthorizedError';
import { securityClient } from 'securityClient';
import { useGetUserDetailsQuery } from 'State/Services/user';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { ConfirmProvider } from "material-ui-confirm";
import './App.css';

function App() {
  const { theme, colorMode } = useMode();
  const { isLoading, isAuthenticated, getAccessTokenSilently, error } = useAuth0();
  const [sidebarState] = useState<'expand' | 'collapse'>('collapse')
  const { data: userDetails, error: userDetailsError, isLoading: isLoadingUserDetails } = useGetUserDetailsQuery(undefined, { skip: !isAuthenticated });
  
  useEffect(() => {
    securityClient.setAccessTokenSilently(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  if (isLoading || isLoadingUserDetails) {
    return (
      <div className="page-layout">
        <PageLoader />
      </div>
    );
  }

  if (error || userDetailsError) {
    return <UnAuthorizedError error={error} />
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ConfirmProvider>
          <CssBaseline />
          <Box className="app">
            {isAuthenticated && <Sidebar state={sidebarState} userDetails={userDetails} />}
            <main className="content">
              <AppRoutes />
            </main>
          </Box>
        </ConfirmProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
