import { Box, Button, FormControl, InputLabel, MenuItem, Modal, Select, Typography, useTheme } from "@mui/material";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import SummarizeIcon from '@mui/icons-material/SummarizeOutlined';
import { useCallback, useEffect, useState } from "react";
import { useLazyGetEstimatesForReportQuery, useLazyGetProjectResourceLevel1ReportQuery, useLazyGetProjectResourceLevel2ReportQuery, useLazyGetProjectResourceLevel3ReportQuery } from "State/Services/report";
import { CategorySubcategoryTotal, CategoryTotal, ReportEstimate } from "Models/report";
import { BaseReportResource } from "Models/base-report-resource";
import ProjectResourceLevel3 from "./ProjectResourceLevel3";
import ProjectResourceLevel2 from "./ProjectResourceLevel2";
import ProjectResourceLevel1 from "./ProjectResourceLevel1";
import { useParams } from "react-router-dom";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { useGetEstimateQuery } from "State/Services/estimate";
import ProjectPriceList from "./ProjectPriceList";
import ProjectListReportDecimals from "./ProjectListReportDecimals";

export default function ReportMain() {
    const { estimateId } = useParams();
    const { data: user } = useGetUserDetailsQuery();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [source, setSource] = useState<string | null>(null);
    const { data: fullEstimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const [estimate, setEstimate] = useState<ReportEstimate | null>(null);
    const [estimates, setEstimates] = useState<Array<ReportEstimate>>([]);
    const [getEstimates] = useLazyGetEstimatesForReportQuery();
    const [reportType, setReportType] = useState<string | null>(null);
    const [report, setReport] = useState<string | null>(null);
    const [reportOptions, setReportOptions] = useState<Array<{ id: string, name: string }>>([]);
    const [generateProjectResourceLevel3Report] = useLazyGetProjectResourceLevel3ReportQuery();
    const [generateProjectResourceLevel2Report] = useLazyGetProjectResourceLevel2ReportQuery();
    const [generateProjectResourceLevel1Report] = useLazyGetProjectResourceLevel1ReportQuery();
    const [baseReportResources, setBaseReportResources] = useState<Array<BaseReportResource>>([]);
    const [categorySubcategoryTotals, setCategorySubcategoryTotals] = useState<Array<CategorySubcategoryTotal>>([]);
    const [categoryTotals, setCategoryTotals] = useState<Array<CategoryTotal>>([]);
    const [hasEstimate, setHasEstimate] = useState(false);
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const [decimals, setDecimals] = useState<number>();

    useEffect(() => {
        if (user && fullEstimate?.EstimateUserRole) {
            if (fullEstimate?.EstimateUserRole?.length === 0) {
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, fullEstimate.EstimateUserRole, {
                    entity: Entity.Reports,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
            }
        } else {
            setHasViewAccess(true);
        }
        setIsReady(true);
    }, [estimate, fullEstimate, user])

    const clearData = useCallback(() => {
        setBaseReportResources([]);
        setCategorySubcategoryTotals([]);
        setCategoryTotals([]);
    }, [])

    const onSourceChange = useCallback(async (source: string | null) => {
        clearData();
        setSource(source);
        setEstimate(null);
        setReportType(null);
        if (user && user.companyId && user.organizationId) {
            const estimateResponse = await getEstimates({
                companyId: user.companyId,
                organizationId: user.organizationId
            });
            if (estimateResponse.data) {
                setEstimates(estimateResponse.data);
            }
        }
    }, [clearData, getEstimates, user])

    const onEstimateChange = useCallback((estimateId: string) => {
        clearData();
        const estimate = estimates.find((est) => (est.id === estimateId));
        if (estimate) {
            setEstimate(estimate);
        }
    }, [clearData, estimates])

    const onReportTypeChange = useCallback((reportType: string | null) => {
        clearData();
        setReportType(reportType);
        switch (reportType) {
            case "allResources":
                setReportOptions([{
                    id: "projctResourceLevel1",
                    name: "Project Resource Level 1"
                },
                {
                    id: "projctResourceLevel2",
                    name: "Project Resource Level 2"
                },
                {
                    id: "projctResourceLevel3",
                    name: "Project Resource Level 3"
                }]);
                break;
            case "price":
                setReportOptions([{
                    id: "projectPriceList",
                    name: "Project Price List"
                }]);
                break;
            default:
                break;
        }
    }, [clearData])

    const onReportChange = useCallback((report: string | null) => {
        setReport(report);
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [])

    const onProjectListGenerate = useCallback((decimals: number | undefined) => {
        setDecimals(decimals);
        handleClose();
    }, [handleClose])

    const generate = useCallback(async () => {
        clearData();
        if (estimate && report && user?.companyId && user?.organizationId) {
            switch (report) {
                case 'projectPriceList':
                        handleOpen();
                    break;
                case 'projctResourceLevel3':
                    let projResourceL3Response = await generateProjectResourceLevel3Report({
                        companyId: user?.companyId,
                        estimateId: estimate.id,
                        organizationId: user?.organizationId
                    });
                    if (projResourceL3Response.data) {
                        setBaseReportResources([...projResourceL3Response.data]);
                    }
                    break;

                case 'projctResourceLevel2':
                    let projResourceL2Response = await generateProjectResourceLevel2Report({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId
                    });
                    if (projResourceL2Response.data) {
                        setCategorySubcategoryTotals(projResourceL2Response.data);
                    }
                    break;
                case 'projctResourceLevel1':
                    let projResourceL1Response = await generateProjectResourceLevel1Report({
                        companyId: user.companyId,
                        estimateId: estimate.id,
                        organizationId: user.organizationId
                    });
                    if (projResourceL1Response.data) {
                        setCategoryTotals(projResourceL1Response.data);
                    }
                    break;
                default:
                    break;
            }

        }
    }, [clearData, estimate, generateProjectResourceLevel1Report, generateProjectResourceLevel2Report, generateProjectResourceLevel3Report, report, user?.companyId, user?.organizationId])

    useEffect(() => {
        const setupReportsForEstimate = async () => {
            if (estimateId) {
                setHasEstimate(true);
                clearData();
                setSource("Estimate");
                if (user && user.companyId && user.organizationId) {
                    const estimateResponse = await getEstimates({
                        companyId: user.companyId,
                        organizationId: user.organizationId
                    });
                    if (estimateResponse.data) {
                        setEstimates(estimateResponse.data);
                        const estimate = estimateResponse.data.find((est) => (est.id === estimateId));
                        if (estimate) {
                            setEstimate(estimate);
                        }
                    }
                }
            }
        }
        setupReportsForEstimate();
    }, [clearData, estimateId, estimates, getEstimates, user])

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }


    return <><Box height={hasEstimate ? "calc(100% - 107px)" : "inherit"} sx={{ borderLeft: `1px solid ${colors.gray[800]}`, boxShadow: `${colors.gray[800]} 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`, }}>
        <Box paddingTop="16px" paddingBottom="16px" paddingLeft="24px" paddingRight="24px" borderBottom={`1px solid ${colors.gray[800]}`}>
            <Typography component="div" sx={{ display: "flex" }}>
                <SummarizeIcon sx={{ width: "20px", height: "20px", color: `${colors.gray[400]} !important`, marginRight: "4px" }} />
                <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>Reports</Box>
            </Typography>
        </Box>
        <Box margin="10px" display="flex" flexDirection="column" height="inherit">
            <Box marginBottom="20px">
                <Typography variant="h4">Select Report</Typography>
            </Box>
            <Box display="flex">
                {!hasEstimate && <Box width="15%" marginRight="20px">
                    <FormControl fullWidth>
                        <InputLabel id="source">Source</InputLabel>
                        <Select
                            labelId="source"
                            value={source}
                            disabled={!hasViewAccess}
                            label="Source"
                            onChange={(event, value) => onSourceChange(event.target.value)}
                        >
                            <MenuItem value={'estimate'}>Estimate</MenuItem>
                            <MenuItem disabled value={'master'}>Master</MenuItem>
                        </Select>
                    </FormControl>
                </Box>}
                {estimates && estimates.length > 0 && !hasEstimate &&
                    <Box width="15%" marginRight="20px">
                        <FormControl fullWidth>
                            <InputLabel id="estimate">Estimate</InputLabel>
                            <Select
                                labelId="estimate"
                                disabled={hasEstimate || !hasViewAccess}
                                value={estimate?.id}
                                label="Estimate"
                                onChange={(event, value) => onEstimateChange(event.target.value)}
                            >
                                {estimates.map((estimate) => (
                                    <MenuItem value={estimate.id}>{estimate.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                }
                {estimate &&
                    <Box width="15%" marginRight="20px">
                        <FormControl fullWidth>
                            <InputLabel id="reportType">Report Type</InputLabel>
                            <Select
                                labelId="reportType"
                                value={reportType}
                                disabled={!hasViewAccess}
                                label="reportType"
                                onChange={(event, value) => onReportTypeChange(event.target.value)}>
                                <MenuItem disabled value={'library'}>Library</MenuItem>
                                <MenuItem disabled value={'directResources'}>Direct Resources</MenuItem>
                                <MenuItem disabled value={'inDirectResources'}>Indirect Resources</MenuItem>
                                <MenuItem value={'allResources'}>All Resources</MenuItem>
                                <MenuItem disabled value={'directCost'}>Direct Cost</MenuItem>
                                <MenuItem disabled value={'indirectCost'}>Indirect Cost</MenuItem>
                                <MenuItem value={'price'}>Price</MenuItem>
                                <MenuItem disabled value={'resourceAllocation'}>Resource Allocation</MenuItem>
                                <MenuItem disabled value={'activityAllocation'}>Activity Allocation</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                }
                {reportType && reportOptions && reportOptions.length > 0 &&
                    <Box width="15%" marginRight="20px">
                        <FormControl fullWidth>
                            <InputLabel id="report">Report</InputLabel>
                            <Select
                                labelId="report"
                                value={report}
                                disabled={!hasViewAccess}
                                label="report"
                                onChange={(event, value) => onReportChange(event.target.value)}
                            >
                                {reportOptions.map((report) => (
                                    <MenuItem value={report.id}>{report.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                }
                {estimates && estimates.length > 0 && reportType && reportOptions && reportOptions.length > 0 && report &&
                    <Box display="flex" alignItems="center">
                        <Button variant="contained" onClick={generate}>Generate</Button>
                    </Box>
                }
            </Box>
            <Box height={hasEstimate ? "inherit" : "calc(100vh - 190px)"} marginTop="20px" display="flex" flexDirection="row">
                {report && report === 'projctResourceLevel3' && baseReportResources && baseReportResources.length > 0 && <ProjectResourceLevel3 baseReportResources={baseReportResources} estimateId={estimate?.id} />}
                {report && report === 'projctResourceLevel2' && categorySubcategoryTotals && categorySubcategoryTotals.length > 0 && <ProjectResourceLevel2 categoryTotals={categorySubcategoryTotals} estimateId={estimate?.id} />}
                {report && report === 'projctResourceLevel1' && categoryTotals && categoryTotals.length > 0 && <ProjectResourceLevel1 categoryTotals={categoryTotals} estimateId={estimate?.id} />}
                {report && report === 'projectPriceList' && <ProjectPriceList estimateId={estimate?.id} decimals={decimals} />}
            </Box>
        </Box>
    </Box>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ProjectListReportDecimals save={onProjectListGenerate} close={() => setOpen(false)} estimateId={estimateId} />
        </Modal>
    </>
}