import HotTable, { HotTableClass } from "@handsontable/react";
import { Box, Button, TextField } from "@mui/material";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { HyperFormula } from 'hyperformula';
import { Entity } from "Models/estimate";
import { IndirectItemDetailView } from "Models/indirect-item";
import { BaseItem, IndirectItemCalculated, ItemCalculated, ItemDetailView } from "Models/item";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useCreateIndirectItemCalculatedMutation, useLazyGetIndirectItemCalculatedQuery, useUpdateIndirectItemCalculatedMutation } from "State/Services/indirect-item";
import { useCreateItemCalculatedMutation, useLazyGetItemCalculatedQuery, useUpdateItemCalculatedMutation } from "State/Services/item";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface QuantityCalculatorProps {
    estimateId: string | undefined;
    directIndirectItemId: string | undefined;
    close: () => void;
    selectedDetail?: ItemDetailView | IndirectItemDetailView | undefined;
    type: "item" | "indirect-item";
    directItem?: BaseItem;
}

export default function QuantityCalculator(props: QuantityCalculatorProps) {
    const { data: user } = useGetUserDetailsQuery();
    const [current, setCurrent] = useState<{ row: number, column: number, value: string } | undefined>();
    const [selected, setSelected] = useState<string>();
    const hotRef = useRef<HotTableClass | null>(null);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [itemCalculated, setItemCalculated] = useState<ItemCalculated | IndirectItemCalculated | undefined>();
    const [itemEntityType, setItemEntityType] = useState<"resource" | "activity" | "subitem" | "item" | "subcontractor">('item');
    const [indirectItemEntityType, setIndirectItemEntityType] = useState<"resource" | "activity" | "subitem" | "item">('item');
    const [cellData, setCellData] = useState<Array<Array<number | null>>>([[null, null, null, null, null], [null, null, null, null, null], [null, null, null, null, null], [null, null, null, null, null], [null, null, null, null, null]]);
    const hyperformulaInstance = HyperFormula.buildEmpty({
        // to use an external HyperFormula instance,
        // initialize it with the `'internal-use-in-handsontable'` license key
        licenseKey: 'internal-use-in-handsontable',
    });
    const [getCalculatedItem] = useLazyGetItemCalculatedQuery();
    const [createCalculatedItem] = useCreateItemCalculatedMutation();
    const [updateCalculatedItem] = useUpdateItemCalculatedMutation();
    const [getCalculatedIndirectItem] = useLazyGetIndirectItemCalculatedQuery();
    const [createCalculatedIndirectItem] = useCreateIndirectItemCalculatedMutation();
    const [updateCalculatedIndirectItem] = useUpdateIndirectItemCalculatedMutation();
    const editDisabledRef = useRef<boolean>();
    const isDisabledDueToAssignedUser = useRef<boolean>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [504]
                });
                editDisabledRef.current = !hasEditAccess;
            }
        } else {
            editDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() =>{
        if (props.directItem?.assignedTo && props.directItem.assignedTo.length > 0) {
            if (props.directItem.assignedTo.some((assignedTo) => (assignedTo.id === user?.userId))) {
                isDisabledDueToAssignedUser.current = false;
            } else {
                isDisabledDueToAssignedUser.current = true;
            }
        } else {
            isDisabledDueToAssignedUser.current = false;
        }
    }, [indirectItemEntityType, itemEntityType, props.directItem?.assignedTo, props.type, user?.userId])

    const setData = useCallback((calculatedItem: any) => {
        const itemCalculated = calculatedItem.data;
        setItemCalculated(itemCalculated);
        if (itemCalculated && hotRef.current) {
            setCellData(JSON.parse(itemCalculated.data));
            const sourceData = JSON.parse(itemCalculated.sourceData) as Array<Array<string | null>>;
            const hot = hotRef.current.hotInstance;
            sourceData.forEach((table, index) => {
                hot?.setSourceDataAtCell([[index, 0, table[0]]]);
                hot?.setSourceDataAtCell([[index, 1, table[1]]]);
                hot?.setSourceDataAtCell([[index, 2, table[2]]]);
                hot?.setSourceDataAtCell([[index, 3, table[3]]]);
                hot?.setSourceDataAtCell([[index, 4, table[4]]]);
            });
        }
    }, [])

    useEffect(() => {
        const entityId = props?.selectedDetail?.id ?? props?.directIndirectItemId;
        let itemEntityType: "resource" | "activity" | "subitem" | "item" | "subcontractor";
        let indirectItemEntityType: "resource" | "activity" | "subitem" | "item";
        if (props.type === "item") {
            switch (props.selectedDetail?.type) {
                case "resource":
                case "activity":
                case "subcontractor":
                    itemEntityType = props.selectedDetail?.type;
                    break;
                case "item":
                    itemEntityType = "subitem"
                    break;
                default:
                    itemEntityType = "item"
                    break;
            }
            setItemEntityType(itemEntityType);
            if (user && entityId && props.estimateId && props.directIndirectItemId) {
                getCalculatedItem({
                    companyId: user.companyId ?? '',
                    organizationId: user.organizationId ?? '',
                    estimateId: props.estimateId,
                    entityType: itemEntityType,
                    entityId: entityId,
                    itemId: props.directIndirectItemId
                }, true).then(setData);
            }
        } else {
            switch (props.selectedDetail?.type) {
                case "resource":
                case "activity":
                    indirectItemEntityType = props.selectedDetail?.type;
                    break;
                case "item":
                    indirectItemEntityType = "subitem"
                    break;
                default:
                    indirectItemEntityType = "item"
                    break;
            }
            setIndirectItemEntityType(indirectItemEntityType);
            if (user && entityId && props.estimateId && props.directIndirectItemId) {
                getCalculatedIndirectItem({
                    companyId: user.companyId ?? '',
                    organizationId: user.organizationId ?? '',
                    estimateId: props.estimateId,
                    entityType: indirectItemEntityType,
                    entityId: entityId,
                    indirectItemId: props.directIndirectItemId
                }, true).then(setData);
            }
        }
        return () => {
            setItemEntityType('item');
            setItemCalculated(undefined);
            setSelected('');
            setCurrent(undefined);
            setCellData([[null, null, null, null, null], [null, null, null, null, null], [null, null, null, null, null], [null, null, null, null, null], [null, null, null, null, null]]);
        };
    }, [getCalculatedItem, props.estimateId, props.directIndirectItemId, props.selectedDetail, user, props.type, setData, getCalculatedIndirectItem])

    const onSelectCell = useCallback((row: number, column: number, row2: number, column2: number, selectionLayerLevel: number) => {
        if (hotRef.current) {
            const hot = hotRef.current.hotInstance;
            const cellEntered = hot?.getSourceDataAtCell(row, column);
            const cellValue = hot?.getDataAtCell(row, column);
            setCurrent({ row, column, value: cellEntered ?? '' });
            setSelected(cellValue ?? '');
        }
    }, [])

    const onCurrentChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (hotRef.current && current) {
            const hot = hotRef.current.hotInstance;
            hot?.setSourceDataAtCell([[current.row, current.column, event.target.value ?? '']]);
            setCurrent({ ...current, value: event.target.value ?? '' })
        }
    }, [current])

    const onSelected = useCallback(async () => {
        const entityId = props?.selectedDetail?.id ?? props?.directIndirectItemId;
        if (hotRef.current && user && entityId && selected) {
            const hot = hotRef.current.hotInstance;
            const data = JSON.stringify(hot?.getData());
            const sourceData = JSON.stringify(hot?.getSourceData());
            if (props.type === "item") {
                if (itemCalculated) {
                    await updateCalculatedItem({
                        companyId: user.companyId ?? '',
                        orgId: user.organizationId ?? '',
                        itemId: props.directIndirectItemId,
                        estimateId: props.estimateId,
                        calculatedId: itemCalculated.id,
                        body: {
                            data: data,
                            sourceData: sourceData,
                            entityId: entityId,
                            entityType: itemEntityType,
                            quantity: parseFloat(selected)
                        }
                    });
                } else {
                    await createCalculatedItem({
                        companyId: user.companyId ?? '',
                        orgId: user.organizationId ?? '',
                        itemId: props.directIndirectItemId,
                        estimateId: props.estimateId,
                        body: {
                            data: data,
                            sourceData: sourceData,
                            entityId: entityId,
                            entityType: itemEntityType,
                            quantity: parseFloat(selected)
                        }
                    });
                }
            } else {
                if (itemCalculated) {
                    await updateCalculatedIndirectItem({
                        companyId: user.companyId ?? '',
                        orgId: user.organizationId ?? '',
                        indirectItemId: props.directIndirectItemId,
                        estimateId: props.estimateId,
                        calculatedId: itemCalculated.id,
                        body: {
                            data: data,
                            sourceData: sourceData,
                            entityId: entityId,
                            entityType: indirectItemEntityType,
                            quantity: parseFloat(selected)
                        }
                    });
                } else {
                    await createCalculatedIndirectItem({
                        companyId: user.companyId ?? '',
                        orgId: user.organizationId ?? '',
                        indirectItemId: props.directIndirectItemId,
                        estimateId: props.estimateId,
                        body: {
                            data: data,
                            sourceData: sourceData,
                            entityId: entityId,
                            entityType: indirectItemEntityType,
                            quantity: parseFloat(selected)
                        }
                    });
                }
            }
            props.close();
        }
    }, [props, user, selected, itemCalculated, updateCalculatedItem, itemEntityType, createCalculatedItem, updateCalculatedIndirectItem, indirectItemEntityType, createCalculatedIndirectItem])

    const cancel = useCallback(() => {
        props.close();
    }, [props])

    return <Box display="flex" flexDirection="column">
        <Box display="flex" width="100%" justifyContent="center" marginBottom="10px" gap="5px">
            <Box display="flex" alignItems="center">Cell:</Box>
            <TextField disabled={editDisabledRef.current || isDisabledDueToAssignedUser.current} sx={{ width: "265px" }} size="small" value={current?.value} onChange={onCurrentChange}></TextField>
        </Box>
        <Box display="flex" flexDirection="column" width="100%" alignItems="center" gap="15px">
            <Box display="flex" justifyContent="center" width="100%" alignContent="center">
                <HotTable
                    ref={hotRef}
                    width="300px"
                    afterSelectionEnd={onSelectCell}
                    data={cellData}
                    rowHeaders={true}
                    colHeaders={true}
                    selectionMode="single"
                    readOnly={editDisabledRef.current || isDisabledDueToAssignedUser.current}
                    outsideClickDeselects={false}
                    formulas={{
                        engine: hyperformulaInstance,
                        sheetName: 'Sheet1',
                    }}
                    height="auto"
                    autoWrapRow={true}
                    autoWrapCol={true}
                    licenseKey="non-commercial-and-evaluation" // for non-commercial use only
                />
            </Box>
            <Box display="flex" width="100%" justifyContent="center" marginBottom="10px" gap="5px">
                <Box display="flex" alignItems="center">Selected:</Box>
                <TextField disabled={true} sx={{ width: "250px" }} size="small" value={selected}></TextField>
            </Box>
            <Box display="flex" gap="15px">
                <Button variant="contained" disabled={!selected || editDisabledRef.current || isDisabledDueToAssignedUser.current} onClick={onSelected}>Use Selected</Button>
                <Button variant="contained" onClick={cancel}>{(editDisabledRef.current || isDisabledDueToAssignedUser.current) ? 'Close' : 'Cancel'}</Button>
            </Box>
        </Box>
    </Box>
}