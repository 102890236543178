import { api } from './api'
import { UserDetails, UserInfo } from 'Models/user-details';

export const userApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUserDetails: build.query<UserDetails, void>({
            query: () => ({ url: 'init' }),
            providesTags: ['User'],
            extraOptions: {maxRetries: 0}
        }),
        getUsersForCompany: build.query<Array<UserInfo>, {orgId: string, companyId: string }>({
            query: ({orgId, companyId}) => ({ url: `organization/${orgId}/company/${companyId}/users` }),
            providesTags: ['Users']
        }),
        assignPermissionsToUser: build.mutation<UserInfo, Partial<{ body: UserInfo, orgId: string, companyId: string, userId: string }>>({
            query({ body, orgId, companyId, userId }) {
              return {
                url: `organization/${orgId}/company/${companyId}/user/${userId}/permissions`,
                method: 'PUT',
                body,
              }
            },
            invalidatesTags: ['Users'],
          })
    })
});

export const {
    endpoints: { getUserDetails },
} = userApi

export const {
    useGetUserDetailsQuery,
    useGetUsersForCompanyQuery,
    useAssignPermissionsToUserMutation
} = userApi