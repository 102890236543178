import { Avatar, Backdrop, Box, Button, CircularProgress, IconButton, List, ListItem, ListItemAvatar, ListItemText, Modal, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetEstimateQuery, useLazyGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ItemView } from "Models/item";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import AssignUsers from "./AssignUsers";
import { EditItems } from "./EditItems";
import { Categories } from "./Categories";
import CloseIcon from '@mui/icons-material/Close';
import { useConfirm } from "material-ui-confirm";
import { useDeleteItemsMutation } from "State/Services/item";
import SubcontractorQuotes from "./SubcontractorQuotes";
import AddIcon from '@mui/icons-material/Add';
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { ItemFilters, ItemFilterSettings } from "Components/Items/ItemFilters";
import SplitButton, { MenuOption } from "Components/SplitButton";
import ItemsFlat from "./ItemsFlat";
import { Import } from "./Import";
import { useExportToOriginalMutation } from "State/Services/file";
import { FileDetails } from "Models/file";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ItemsGrouped from "./ItemsGrouped";
import { format } from "date-fns";

const assignUsersModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "350px",
    height: "200px",
    bgcolor: 'background.paper',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: "column"

};
export default function DirectItemsIndex() {
    const confirm = useConfirm();
    const theme = useTheme();
    const [deleteItems] = useDeleteItemsMutation();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const { estimateId } = useParams();
    const [colors, setColors] = useState<any>();
    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100% - 120px)' }), []);
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const { data: user } = useGetUserDetailsQuery();
    const [exportMenuItems] = useState<Array<MenuOption>>([{ option: 'Export to Excel', disabled: false }, { option: 'Export to Imported File', disabled: false }, { option: 'Export to Imported File (Rate only)', disabled: false }]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const [selectedRows, setSelectedRows] = useState<Array<ItemView>>([]);
    const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [fileSelected, setFileSelected] = useState('');
    const [openSubcontractorModal, setOpenSubcontractorModal] = useState(false);
    const [openAssignUsersModal, setOpenAssignUsersModal] = useState(false);
    const [grouped, setGrouped] = useState(false);
    const [editDisabled, setEditDisabled] = useState<boolean>();
    const [deleteDisabled, setDeleteDisabled] = useState<boolean>();
    const [exportOption, setExportOption] = useState<"ExportAll" | "ExportRateOnly">();
    const itemGridRef = useRef<any>();
    const [autoRowHeightEnabled, setAutoRowHeightEnabled] = useState<boolean>(false);
    const [getEstimate] = useLazyGetEstimateQuery();
    const [exportToOriginal] = useExportToOriginalMutation();
    const [hasViewAccess, setHasViewAccess] = useState(false);

    const [openExportModal, setOpenExportModal] = useState(false);
    const handleOpenExportModal = () => {
        setOpenExportModal(true);
    };
    const handleCloseExportModal = () => {
        setOpenExportModal(false);
    };

    const handleOpenAssignUsersModal = () => {
        setOpenAssignUsersModal(true);
    };
    const handleCloseAssignUsersModal = (deselect?: boolean) => {
        setOpenAssignUsersModal(false);
        if (deselect) {
            itemGridRef.current.reloadAndClearFilters();
        }
    };

    const handleOpenCategoriesModal = () => {
        setOpenCategoriesModal(true);
    };
    const handleCloseCategoriesModal = async () => {
        setOpenCategoriesModal(false);
    };
    const [openEditModal, setOpenEditModal] = useState(false);
    const handleOpenEditModal = () => {
        setOpenEditModal(true);
    };
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        itemGridRef.current.reloadAndClearFilters();
    };

    const [openImportModal, setOpenImportModal] = useState(false);
    const handleOpenImportModal = () => {
        setFileSelected('');
        setOpenImportModal(true);
    };

    const handleCloseImportModal = async () => {
        setFileSelected('');
        setOpenImportModal(false);
        itemGridRef.current.reloadAndClearFilters();
    };

    const editSelected = () => {
        handleOpenEditModal();
    }

    const handleOpenSubcontractorModal = () => {
        setOpenSubcontractorModal(true);
    };
    const handleCloseSubcontractorModal = () => {
        setOpenSubcontractorModal(false);
    };

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setHasViewAccess(true);
                setEditDisabled(true);
                setDeleteDisabled(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [502]
                });
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [503]
                });
                setEditDisabled(!hasEditAccess);
                setDeleteDisabled(!hasDeleteAccess);
            }
        } else {
            setHasViewAccess(true);
            setEditDisabled(true);
            setDeleteDisabled(true);
        }
    }, [estimate, estimate?.EstimateUserRole, user])
    const editDisabledRef = useRef<boolean>();
    editDisabledRef.current = editDisabled;
    const deleteDisabledRef = useRef<boolean>();
    deleteDisabledRef.current = deleteDisabled;

    const deleteSelected = async () => {
        try {
            if (selectedRows && selectedRows.length > 0) {
                await confirm({ description: `Are you sure you want to delete ${selectedRows.length} records?`, title: 'Delete confirmation' });
                setOpenBackdrop(true);
                await deleteItems({
                    body: selectedRows.map((item) => {
                        if (item.id) {
                            return item.id
                        }
                        return ''
                    }),
                    companyId: user?.companyId,
                    estimateId: estimateId,
                    orgId: user?.organizationId
                });
                setOpenBackdrop(false);
                itemGridRef.current.reloadAndClearFilters();
            }
        } catch (error) { }
    }

    const getSubcontractorQuote = useCallback(() => {
        if (selectedRows && selectedRows.length > 0) {
            setOpenSubcontractorModal(true);
        }
    }, [selectedRows])

    const assignItemToUsers = useCallback(async () => {
        handleOpenAssignUsersModal();
    }, [])

    const addItem = useCallback(() => {
        itemGridRef.current.addNewRow();
    }, [])

    const onFilterChanged = useCallback((settings: ItemFilterSettings) => {
        itemGridRef.current.setFilterChanged(settings);
    }, [])

    const exportExcel = useCallback(async (option?: string) => {
        let rateOnly = false;
        switch (option) {
            default:
            case "Export to Excel":
                itemGridRef.current.exportAsExcel();
                return;
            case "Export to Imported File":
                setExportOption("ExportAll");
                break;
            case "Export to Imported File (Rate only)":
                rateOnly = true;
                setExportOption("ExportRateOnly");
                break;
        }
        if (user) {
            const estimate = await getEstimate({
                companyId: user.companyId ?? '',
                organizationId: user.organizationId,
                estimateId: estimateId ?? '',
            }, true).unwrap();
            if (estimate?.File && estimate?.File?.length > 0) {
                if (estimate && estimate?.File) {
                    if (estimate?.File?.length > 1) {
                        handleOpenExportModal();
                    }
                    else {
                        await exportToOriginal({
                            companyId: user?.companyId,
                            estimateId: estimateId,
                            organizationId: user.organizationId,
                            fileId: estimate.File[0]?.id,
                            rateOnly: rateOnly
                        });
                    }
                }
            }
        }
    }, [estimateId, exportToOriginal, getEstimate, user]);

    const onSelection = useCallback((rows: Array<ItemView>) => {
        setSelectedRows(rows);
    }, [])

    const enableDisableRowAutoHeight = useCallback(() => {
        setAutoRowHeightEnabled(!autoRowHeightEnabled);
        itemGridRef.current.setAutoheight(!autoRowHeightEnabled);

    }, [autoRowHeightEnabled])

    const exportFile = useCallback(async (file: FileDetails) => {
        if (user) {
            await exportToOriginal({
                companyId: user?.companyId,
                estimateId: estimateId,
                organizationId: user.organizationId,
                fileId: file.id,
                rateOnly: exportOption === "ExportRateOnly"
            });
        }
    }, [estimateId, exportOption, exportToOriginal, user])

    const switchGrid = useCallback(() => {
        setSelectedRows([]);
        if (grouped) {
            setGrouped(false);
        } else {
            setGrouped(true);
        }
    }, [grouped])

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <>{colors &&
        <><Box style={containerStyle}>
            <Box height="100%" margin="10px" display="flex" justifyContent="space-between" flexDirection="column" gap="15px">
                {colors && estimateId &&
                    <>
                        <Box display="flex" flexDirection="row" width="100%">
                            <Box display="flex" flexDirection="column" gap="20px" justifyContent="space-between">
                                {estimate && estimate.noOfLevels > 0 && <Box marginRight="10px" marginTop="2px">
                                    <Button
                                        variant="outlined"
                                        disabled={!!editDisabledRef.current}
                                        startIcon={<CategoryOutlinedIcon />}
                                        onClick={handleOpenCategoriesModal}>Categories</Button>
                                </Box>}
                                <Box marginRight="10px" marginBottom="2px">
                                    <Button
                                        fullWidth
                                        startIcon={<AddIcon />}
                                        disabled={!!editDisabledRef.current}
                                        variant="contained"
                                        onClick={addItem}>New</Button>
                                </Box>
                            </Box>
                            <Box flex="1">
                                {estimateId && <ItemFilters estimateId={estimateId} onFilterChanged={onFilterChanged} />}
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Box display="flex">
                                    <Box marginRight="10px">
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={grouped}
                                            size="small"
                                            disabled={!!isEditing}
                                            exclusive
                                            onChange={switchGrid}
                                            aria-label="Tree"
                                        >
                                            <ToggleButton value={false}>List View</ToggleButton>
                                            <ToggleButton value={true}>Tree View</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                    <Box>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={autoRowHeightEnabled}
                                            size="small"
                                            disabled={!!isEditing}
                                            exclusive
                                            onChange={enableDisableRowAutoHeight}
                                            aria-label="Autoheight"
                                        >
                                            <ToggleButton value={false}>Condensed</ToggleButton>
                                            <ToggleButton value={true}>AutoFit</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="end" alignItems="center" marginTop="20px">
                                    <Box marginRight="10px">
                                        <Button
                                            sx={{ height: "35px" }}
                                            variant="outlined"
                                            startIcon={<FileDownloadOutlinedIcon />}
                                            disabled={isEditing || !!editDisabledRef.current}
                                            onClick={handleOpenImportModal}>Import</Button>
                                    </Box>
                                    <Box>
                                        <SplitButton
                                            disabled={isEditing} buttonHeight="35px" variant="outlined"
                                            options={exportMenuItems} buttonText="Export"
                                            onButtonClick={exportExcel} onMenuItemClick={exportExcel} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {!grouped && <Box display="flex" flexDirection="column">
                            <Box display="flex">
                                {selectedRows.length > 0 && !isEditing &&
                                    <>
                                        <Box marginRight="10px">
                                            <Button
                                                color="primary"
                                                disabled={selectedRows?.length > 500 || !!editDisabledRef.current}
                                                variant="contained"
                                                startIcon={<EditIcon />}
                                                onClick={editSelected}>Edit</Button>
                                        </Box>
                                        <Box marginRight="10px">
                                            <Button
                                                variant="contained"
                                                disabled={selectedRows?.length > 500 || !!editDisabledRef.current}
                                                sx={{ backgroundColor: `${colors?.redAccent[500]}` }}
                                                startIcon={<DeleteIcon />}
                                                onClick={deleteSelected}>Delete</Button>
                                        </Box>
                                        <Box marginRight="10px">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={!!editDisabledRef.current}
                                                startIcon={<EngineeringOutlinedIcon />}
                                                onClick={getSubcontractorQuote}>Get Quotes</Button>
                                        </Box>
                                        <Box marginRight="10px">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={!!editDisabledRef.current}
                                                startIcon={<AssignmentIndOutlinedIcon />}
                                                onClick={assignItemToUsers}>Assign</Button>
                                        </Box>
                                    </>
                                }
                            </Box>
                            {selectedRows?.length > 0 && !isEditing &&
                                <Box marginTop="10px">
                                    <Typography variant="body1" color={selectedRows?.length > 500 ? `${colors.redAccent[500]}` : `${colors.greenAccent[300]}`}>
                                        {selectedRows?.length} records selected. Select max 500 records.
                                    </Typography>
                                </Box>
                            }
                        </Box>}
                    </>
                }

                {!grouped && <ItemsFlat estimateId={estimateId} onSelected={onSelection} ref={itemGridRef} onEdit={setIsEditing} />}
                {grouped && <ItemsGrouped estimateId={estimateId} onSelected={onSelection} ref={itemGridRef} />}
            </Box>
        </Box>
            <Modal
                open={openSubcontractorModal}
                onClose={handleOpenSubcontractorModal}
                aria-labelledby="subcontractor-items-title"
                aria-describedby="subcontractor-items-description"
            >
                <Box className="small-modal">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="center"
                        marginBottom="10px"
                        borderBottom={`1px solid ${colors?.gray[800]}`}>
                        <Box padding="10px">
                            {colors && <Typography variant="h4" color="primary">Subcontractor quotes for {selectedRows?.length} records</Typography>}
                        </Box>
                        <Box>
                            <IconButton aria-label="edit" color="primary" onClick={handleCloseSubcontractorModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {estimateId && estimate && <SubcontractorQuotes items={selectedRows} estimateId={estimateId} closeModal={handleCloseSubcontractorModal} />}
                </Box>
            </Modal>
            <Modal
                open={openAssignUsersModal}
                onClose={handleOpenAssignUsersModal}
                aria-labelledby="assign-items-title"
                aria-describedby="assign-items-description"
            >
                <Box sx={{ ...assignUsersModalStyle }}>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="center"
                        marginBottom="10px"
                        borderBottom={`1px solid ${colors?.gray[800]}`}>
                        <Box padding="10px">
                            {colors && <Typography variant="h4" color="primary">Assign users for {selectedRows?.length} records</Typography>}
                        </Box>
                        <Box>
                            <IconButton aria-label="edit" color="primary" onClick={() => handleCloseAssignUsersModal(false)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {estimateId && estimate && <AssignUsers items={selectedRows} estimateId={estimateId} closeModal={handleCloseAssignUsersModal} />}
                </Box>
            </Modal>
            <Modal
                open={openEditModal}
                onClose={handleCloseEditModal}
                aria-labelledby="edit-items-title"
                aria-describedby="edit-items-description"
            >
                <Box className="small-modal">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="center"
                        marginBottom="10px"
                        borderBottom={`1px solid ${colors?.gray[800]}`}>
                        <Box padding="10px">
                            {colors && <Typography variant="h4" color="primary">Bulk edit {selectedRows?.length} records</Typography>}
                        </Box>
                        <Box>
                            <IconButton aria-label="edit" color="primary" onClick={handleCloseEditModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    {estimateId && estimate && <EditItems estimateId={estimateId} noOfLevels={estimate.noOfLevels} closeModal={handleCloseEditModal} items={selectedRows} />}
                </Box>
            </Modal>
            <Modal
                open={openImportModal}
                onClose={handleCloseImportModal}
                aria-labelledby="import-modal"
                aria-describedby="import-modal-description"
            >
                <Box className="large-modal">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="center"
                        marginBottom="10px"
                        borderBottom={`1px solid ${colors?.gray[800]}`}>
                        <Box padding="10px">
                            {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Import from file{(fileSelected) ? `: ${fileSelected}` : ''}</Typography>}
                        </Box>
                        <Box>
                            <IconButton aria-label="delete" onClick={handleCloseImportModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Import estimateId={estimateId} noOfLevels={estimate?.noOfLevels} onFileSelected={setFileSelected} onClose={handleCloseImportModal} />
                </Box>
            </Modal>
            <Modal
                open={openExportModal}
                onClose={handleCloseExportModal}
                aria-labelledby="export-items-title"
                aria-describedby="export-items-description"
            >
                <Box className="small-modal">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="center"
                        marginBottom="10px"
                        borderBottom={`1px solid ${colors?.gray[800]}`}>
                        <Box padding="10px">
                            {colors && <Typography variant="h4" color="primary">Export file</Typography>}
                        </Box>
                        <Box>
                            <IconButton aria-label="edit" color="primary" onClick={handleCloseExportModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                        <List dense={true} sx={{ height: "60%", width: "100%", overflow: "auto" }}>
                            {estimate?.File?.map((file) => {
                                return <ListItem
                                    secondaryAction={
                                        <IconButton edge="end" aria-label="export" onClick={() => exportFile(file)}>
                                            <FileDownloadIcon />
                                        </IconButton>
                                    }>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <AttachFileIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={file.originalName}
                                        secondary={`Uploaded on ${format(new Date(file.createDate).getTime(), 'dd/MM/yyyy')}`}
                                    />
                                </ListItem>
                            })}
                        </List>
                        <Box margin="20px">
                            <Button variant="outlined" onClick={handleCloseExportModal}>Close</Button>
                        </Box>
                    </Box>

                </Box>
            </Modal>
            <Modal
                open={openCategoriesModal}
                onClose={handleCloseCategoriesModal}
                aria-labelledby="categories-modal"
                aria-describedby="categories-modal-description"
            >
                <Box className="large-modal">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="center"
                        borderBottom={`1px solid ${colors?.gray[800]}`}>
                        <Box padding="10px">
                            {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Categories</Typography>}
                        </Box>
                        <Box>
                            <IconButton aria-label="delete" onClick={handleCloseCategoriesModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <Categories estimateId={estimateId} noOfLevels={estimate?.noOfLevels} isImport={false} />
                </Box>
            </Modal>
            <Backdrop
                sx={{ color: colors?.primary[1000], zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    }
    </>
}